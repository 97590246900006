exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-new-js": () => import("./../../../src/pages/AboutNew.js" /* webpackChunkName: "component---src-pages-about-new-js" */),
  "component---src-pages-calendar-js": () => import("./../../../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-contact-new-js": () => import("./../../../src/pages/ContactNew.js" /* webpackChunkName: "component---src-pages-contact-new-js" */),
  "component---src-pages-csae-js": () => import("./../../../src/pages/csae.js" /* webpackChunkName: "component---src-pages-csae-js" */),
  "component---src-pages-festival-js": () => import("./../../../src/pages/festival.js" /* webpackChunkName: "component---src-pages-festival-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-order-js": () => import("./../../../src/pages/order.js" /* webpackChunkName: "component---src-pages-order-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/Projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-qr-1-js": () => import("./../../../src/pages/qr1.js" /* webpackChunkName: "component---src-pages-qr-1-js" */),
  "component---src-pages-qr-2-js": () => import("./../../../src/pages/qr2.js" /* webpackChunkName: "component---src-pages-qr-2-js" */),
  "component---src-pages-qr-3-js": () => import("./../../../src/pages/qr3.js" /* webpackChunkName: "component---src-pages-qr-3-js" */),
  "component---src-pages-qr-4-js": () => import("./../../../src/pages/qr4.js" /* webpackChunkName: "component---src-pages-qr-4-js" */),
  "component---src-pages-qr-5-js": () => import("./../../../src/pages/qr5.js" /* webpackChunkName: "component---src-pages-qr-5-js" */),
  "component---src-pages-qr-6-js": () => import("./../../../src/pages/qr6.js" /* webpackChunkName: "component---src-pages-qr-6-js" */),
  "component---src-pages-qr-7-js": () => import("./../../../src/pages/qr7.js" /* webpackChunkName: "component---src-pages-qr-7-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

